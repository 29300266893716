// Routes.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Accueil from '../pages/Accueil';
import Contact from '../pages/Contact';
import Projet from '../pages/Projet';
import Error404 from '../pages/Error404';

const RoutesConfig = () => {
  return (
    <Routes>
      <Route path="/" element={<Accueil />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/projets" element={<Projet />} />
      <Route path="/*" element={<Error404 />} />
    </Routes>
  );
};

export default RoutesConfig;
