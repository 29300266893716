import React from 'react';
import '../styles/contact.scss';
import Nav from '../component/Nav';

const Contact = () => {
  return (
    <div><Nav />
    <div className="invert-filter">
      
      <div className="inner-filter">
      <iframe className='iframe' src="https://docs.google.com/forms/d/e/1FAIpQLSf5bcJHrsDluVW_8tGqxW2qu8pD-tXMlajfkoDy5lyLMFjv6Q/viewform?embedded=true"  frameborder="0" marginheight="0" marginwidth="0">Chargement…</iframe>
      </div>
    </div>
    </div>
  );
}

export default Contact;
