import React, { useState } from 'react';
import projettab from '../component/Projettab'; // Assurez-vous que le chemin d'importation est correct
import '../styles/Projet.scss'; // Assurez-vous d'importer le fichier SCSS pour les styles

import FlecheG from '../images/Projet/Main/flecheG.png'
import FlecheD from '../images/Projet/Main/flecheD.png'
import Redirect from '../images/Projet/redirect.svg'
import Nav from '../component/Nav';


const Projet = () => {
  // Initialisation de l'état pour gérer l'index de l'image pour chaque projet
  const [currentImgIndexArray, setCurrentImgIndexArray] = useState(
    projettab.map(() => 0) // Initialisation avec un tableau de zéros de même longueur que projettab
  );

  // Fonction pour avancer à la diapositive suivante pour un projet spécifique
  const nextSlide = (index) => {
    setCurrentImgIndexArray(prevState => {
      const newIndexes = [...prevState];
      newIndexes[index] = (newIndexes[index] + 1) % projettab[index].images.length;
      return newIndexes;
    });
  };

  // Fonction pour reculer à la diapositive précédente pour un projet spécifique
  const prevSlide = (index) => {
    setCurrentImgIndexArray(prevState => {
      const newIndexes = [...prevState];
      newIndexes[index] = (newIndexes[index] - 1 + projettab[index].images.length) % projettab[index].images.length;
      return newIndexes;
    });
  };

  const [expanded, setExpanded] = useState(Array(projettab.length).fill(false)); // Tableau pour gérer l'expansion du texte pour chaque projet

  // Fonction pour obtenir les deux premières phrases du texte
  const getFirstTwoSentences = (text) => {
    const sentences = text.split('. ');
    if (sentences.length > 1) {
      return `${sentences[0]}. ${sentences[1]}.`;
    } else {
      return text;
    }
  };

  return (
    <div>
      <Nav />
      <div className="projet-list">
        {projettab.map((projet, index) => (
          <div className="projet" key={projet.id}>
            <div className="slider-container">
              <img className="slider-image" src={projet.images[currentImgIndexArray[index]]} alt={`Slide ${currentImgIndexArray[index] + 1}`} />
              {projet.images.length > 1 && ( // N'afficher les flèches que s'il y a plus d'une image
                <div className="slider-navigation">
                  <button onClick={() => prevSlide(index)}><img src={FlecheD} alt="flecheG" /></button>
                  <button onClick={() => nextSlide(index)}><img src={FlecheG} alt="flecheD" /></button>
                </div>
              )}
            </div>
            <div className={`projet-details ${expanded[index] ? 'expanded' : ''}`}>
              <h2>{projet.titre}</h2>
              <br></br>
              <p className={`projet-text ${expanded[index] ? 'expanded' : ''}`}>
                {expanded[index] ? projet.texte : getFirstTwoSentences(projet.texte)}
                {projet.redirect && (
                  <a className="redirect-button" href={projet.redirect} target="_blank" rel="noreferrer">
                    <br></br>
                    <img src={Redirect} alt="redirection" />
                  </a>
                )}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projet;
