import React from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/Nav.scss';

import LogoG from '../images/Nav/LogoG.svg';
import LogoD from '../images/Nav/LogoD.svg';
import Logo from '../images/Nav/Logo.svg';
import PointRouge from '../images/Nav/Pointrouge.svg';
import PointBlanc from '../images/Nav/Pointblanc.svg';
import Line from '../images/Nav/Line.png'
import Ligne from '../images/Nav/Ligne.svg'

export default function Nav() {
  return (
    <nav>
      <div className='Nav-top'>
        <div className='nav-desktop'>
          <img src={LogoG} alt="LogoG" />
        </div>
        <div className='Logo' >
          <NavLink to="/">
            <img src={Logo} alt="Logo" />
          </NavLink>
        </div>
        <div className='nav-desktop'>
          <img src={LogoD} alt="LogoD" />
        </div>
      </div>
      <div className='Nav-bot'> 
        <div className='nav-item'>
          <NavLink to='/' activeClassName='active'>
            <p>ACCUEIL</p>
            <div className='points'>
              <img src={PointRouge} alt="Point rouge" className='point-rouge' />
              <img src={PointBlanc} alt="Point blanc" className='point-blanc' />
            </div>
          </NavLink>
        </div>
        <div className='ligne'><img src={Ligne} alt="trait"/></div>
        <div className='line'><img src={Line} alt="trait"/></div>
        <div className='nav-item'>
          <NavLink to='/projets' activeClassName='active'>
            <p>PROJETS</p>
            <div className='points'>
              <img src={PointRouge} alt="Point rouge" className='point-rouge' />
              <img src={PointBlanc} alt="Point blanc" className='point-blanc' />
            </div>
          </NavLink>
        </div>
        <div className='ligne'><img src={Ligne} alt="trait"/></div>
        <div className='line'><img src={Line} alt="trait"/></div>
        <div className='nav-item'>
          <NavLink to='/contact' activeClassName='active'>
            <p>CONTACT</p>
            <div className='points'>
              <img src={PointRouge} alt="Point rouge" className='point-rouge' />
              <img src={PointBlanc} alt="Point blanc" className='point-blanc' />
            </div>
          </NavLink>
        </div>
      </div>
    </nav>
  );
}
