// Projettab.js

const projettab = [
  {
    id: 1,
    images: [
      require('../images/Projet/Http/AccueilHTTP.webp'),
      require('../images/Projet/Http/contact.webp'),
      require('../images/Projet/Http/Prez.webp'),
      require('../images/Projet/Http/Realisations.webp'),
    ],
    titre: "HoverTheTop",
    texte: "Notre site de collaboration professionnelle",
    redirect : "https://hoverthetop.fr/",
  },
  {
    id: 2,
    images: [
      require('../images/Projet/Template/Maquettevin.webp'),
    
    ],
    titre: "",
    texte: "Un de nos premiers Templates collaboratif",
    redirect : "https://www.figma.com/design/MaSQMQt8BmZOTXyQuT3QA1/Maquette---Vitrine---Vino-Grieco?node-id=0-1&t=HNWpOJnsOH0FXpVE-1",
  }
  ,
  {
    id: 3,
    images: [
      require('../images/Projet/Template/Maquettebiere.webp'),

    ],
    titre: "",
    texte: "Un de nos premiers Templates collaboratif",
    redirect : "https://www.figma.com/proto/Ea9ZJLRz9i6zS4tJTDJ3H7/Maquette---Vitrine---Ice-bottled-cube?node-id=1-2&starting-point-node-id=1%3A2&t=2rg49mKg9xasj87C-1",
  }
  ,
  {
    id: 4,
    images: [
      require('../images/Projet/NosSites/Emilien/Accueil.webp'),
      require('../images/Projet/NosSites/Emilien/Bio.webp'),
      require('../images/Projet/NosSites/Emilien/Projet.webp'),

    ],
    titre: "Eminence Graphique",
    texte: "Site d'un Graphiste collaborateur HoverTheTop",
    redirect : "https://eminencegraphique.fr/",
  }
  ,
  {
    id: 5,
    images: [
      require('../images/Projet/EFT/acceuil1.webp'),
      require('../images/Projet/EFT/acceuil2.webp'),
      require('../images/Projet/EFT/acceuil.webp'),
      require('../images/Projet/EFT/contact1.webp'),
      require('../images/Projet/EFT/FAQ.webp'),
      require('../images/Projet/EFT/gestionsalle1.webp'),
      require('../images/Projet/EFT/Paiement.webp'),
      require('../images/Projet/EFT/Témoignages.webp'),
      require('../images/Projet/EFT/abonnement.webp'),
     
    ],
    titre: "Skilled Trading",
    texte: "Site de traiding "
  }
  // Ajoutez d'autres projets si nécessaire
];

export default projettab;
